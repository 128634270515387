import { createFileRoute } from '@tanstack/react-router';

// import { preloadChatList } from '@myn/ai-hub/loaders';

export const Route = createFileRoute('/_secured/ai-hub')({
  loader: async ({ context }) => {
    await context.serviceLocator.getInstance('i18n', '@myn/ai-hub');
    // await preloadChatList();
  },
});
