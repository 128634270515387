import { useCallback, type Dispatch, type SetStateAction } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import { useTranslation } from '@mint-lib/i18n';
import {
  useIsAgencyCompany,
  usePermissions,
  useProfile,
} from '@mint-lib/profile-helpers';
import { useFlags } from '@mint-lib/routing-context';

import {
  Avatar,
  AvatarSize,
  Box,
  Divider,
  OptionType,
  Select,
  SelectWithSearch,
} from '@myn/mui';

import { UserModalFormType } from '../../api/users/users.types.js';
import ConditionalSkeleton from '../../atoms/ConditionalSkeleton/ConditionalSkeleton.jsx';
import FormBlock from '../../atoms/FormBlock/index.js';
import UserDataBlock from '../../molecules/UserDataBlock/UserDataBlock.jsx';

export type ExistingUserView = {
  formInstance: UseFormReturn<UserModalFormType>;
  isReadonlyPermission: boolean;
  roleOptions: OptionType[];
  clientsOptions: OptionType[] | null;
  assignedClients: number[] | null;
  setAssignedClients: Dispatch<SetStateAction<number[] | null>>;
  isLoading: boolean;
};

const ExistingUserView = ({
  formInstance,
  isReadonlyPermission,
  roleOptions,
  clientsOptions,
  assignedClients,
  setAssignedClients,
  isLoading,
}: ExistingUserView) => {
  const { t } = useTranslation('@myn/permissions');

  const ff = useFlags('@mint/arm', ['userManagemetFixes', 'ssoIdpProvider']);

  const { profile, profileService } = useProfile();
  const { getPermissionField } = usePermissions();

  const isIdpProvider = profileService.isIdpProvider();
  const isAgencyCompany = useIsAgencyCompany();

  const isOwnUser = formInstance.getValues('id') === profile?.id;

  const handleRoleChange = useCallback(
    (e: number | '') => {
      formInstance.setValue('role', e, {
        shouldDirty: true,
      });
      formInstance.trigger('role');
    },
    [formInstance],
  );

  const handleAssignedClientsChange = useCallback(
    (e: number[]) => {
      e && setAssignedClients(e);

      formInstance.setValue('assignedClients', e, {
        shouldDirty: true,
      });
      formInstance.trigger('assignedClients');
    },
    [formInstance, setAssignedClients],
  );

  return (
    <>
      <Box sx={{ display: 'flex', gap: (theme) => theme.spacing(3) }}>
        <ConditionalSkeleton
          variant="circular"
          height={80}
          width={80}
          isLoading={isLoading}
        >
          <Avatar
            variant="circular"
            bgColor="secondary"
            avatarSize={AvatarSize.mega}
            src={formInstance.getValues('avatar') || undefined}
            firstName={formInstance.getValues('firstName')}
            lastName={formInstance.getValues('lastName')}
          />
        </ConditionalSkeleton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: (theme) => theme.spacing(2),
          }}
        >
          <Box sx={{ display: 'flex', gap: (theme) => theme.spacing(3) }}>
            <ConditionalSkeleton
              height={36}
              width={ff.userManagemetFixes.enabled ? 150 : 70}
              isLoading={isLoading}
            >
              <UserDataBlock
                label={t(`First name`)}
                data={formInstance.getValues('firstName')}
              />
            </ConditionalSkeleton>
            <ConditionalSkeleton
              height={36}
              width={ff.userManagemetFixes.enabled ? 150 : 70}
              isLoading={isLoading}
            >
              <UserDataBlock
                label={t(`Last name`)}
                data={formInstance.getValues('lastName')}
              />
            </ConditionalSkeleton>
          </Box>
          <ConditionalSkeleton height={36} width={200} isLoading={isLoading}>
            <UserDataBlock
              label={t(`Email`)}
              data={formInstance.getValues('email')}
              maxWidth={ff.userManagemetFixes.enabled ? 365 : undefined}
            />
          </ConditionalSkeleton>
          <Box sx={{ display: 'flex', gap: (theme) => theme.spacing(3) }}>
            <ConditionalSkeleton
              height={36}
              width={ff.userManagemetFixes.enabled ? 150 : 70}
              isLoading={isLoading}
            >
              <UserDataBlock
                label={t(`Mobile number`)}
                data={formInstance.getValues('mobileNumber')}
              />
            </ConditionalSkeleton>
            <ConditionalSkeleton
              height={36}
              width={ff.userManagemetFixes.enabled ? 150 : 70}
              isLoading={isLoading}
            >
              <UserDataBlock
                label={t(`Office number`)}
                data={formInstance.getValues('officeNumber')}
              />
            </ConditionalSkeleton>
          </Box>
        </Box>
      </Box>

      <Divider />

      <FormBlock>
        <Box sx={{ flexGrow: '1' }}>
          <ConditionalSkeleton height={60} width={488} isLoading={isLoading}>
            <Select
              truncatedOptions
              label={t('Role*')}
              placeholder={t('Select role')}
              options={roleOptions}
              value={Number(formInstance.getValues('role')) || ''}
              disabled={
                isReadonlyPermission ||
                isOwnUser ||
                (ff.ssoIdpProvider.enabled && isIdpProvider)
              }
              infoText={t(`This role will be applied to the agency workspace.`)}
              TooltipProps={{ placement: 'top' }}
              {...formInstance.register('role', { required: true })}
              onChange={handleRoleChange}
              fullWidth
            />
          </ConditionalSkeleton>
        </Box>
      </FormBlock>

      {isAgencyCompany ? (
        <FormBlock>
          <Box sx={{ flexGrow: '1', maxWidth: '488px' }}>
            <ConditionalSkeleton height={60} width={488} isLoading={isLoading}>
              <SelectWithSearch
                truncatedOptions
                label={t('Clients')}
                placeholder={t('Assign clients')}
                multiple
                infoText={t(`Assign clients to this user`)}
                TooltipProps={{ placement: 'top' }}
                options={clientsOptions ?? []}
                value={assignedClients ?? []}
                disabled={
                  isReadonlyPermission ||
                  (ff.ssoIdpProvider.enabled && isIdpProvider)
                }
                {...formInstance.register('assignedClients')}
                onChange={handleAssignedClientsChange}
                fullWidth
              />
            </ConditionalSkeleton>
          </Box>
        </FormBlock>
      ) : null}
    </>
  );
};

export default ExistingUserView;
